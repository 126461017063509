// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

document.addEventListener("DOMContentLoaded", function() {
//   const boxes = document.querySelectorAll('.expanding-boxes__box');

//   boxes.forEach(box => {
//       box.addEventListener('click', function() {
//           const content = this.querySelector('.expanding-boxes__content');
//           if (content.style.maxHeight === '500px') {
//               content.style.maxHeight = '80px';
//           } else {
//               content.style.maxHeight = '500px';
//           }
//       });
//   });

//   const areas = document.querySelectorAll('.list__area');

//   areas.forEach(area => {
//       area.addEventListener('click', function() {
//           const content = this.querySelector('.content');
//           if (content.style.maxHeight === '500px') {
//               content.style.maxHeight = '182px';
//           } else {
//               content.style.maxHeight = '500px';
//           }
//       });
//   });

// Get all elements with the class "parent-sub-menu"
var parentSubMenus = document.querySelectorAll('.ps-parent-sub-menu');

// Iterate over each parent sub-menu
parentSubMenus.forEach(function(parentSubMenu) {
    // Get the href attribute value of the first <a> tag in the sub-menu
    var firstLink = parentSubMenu.querySelector('a');
    if (firstLink) {
        var firstLinkHref = firstLink.getAttribute('href');

        // Create a new link
        var newLink = document.createElement('a');
        newLink.setAttribute('href', firstLinkHref);
        newLink.textContent = 'Learn more'; // Set the text content of the new link
        newLink.classList.add('sub-menu-learn-more'); // Add the "learn-more" class to the new link

        // Replace the href attribute value of the first <a> tag with "javascript:void(0)"
        firstLink.setAttribute('href', 'javascript:void(0)');
        firstLink.setAttribute('tabindex', '-1'); // Add tabindex attribute with value -1

        // Insert the new link into the parent sub-menu
        parentSubMenu.appendChild(newLink);
    }
});

// Function to toggle the "show" class on the parent <li> element
function toggleShowClass(event) {
    // If the clicked element is an <a> tag and not inside a sub-menu, prevent its default action
    if (event.target.tagName === 'A' && !event.target.closest('.sub-menu')) {
        event.preventDefault(); // Prevent default link behavior

        // Toggle the "show" class on the clicked element's parent .menu-item-has-children
        event.target.closest('.menu-item-has-children').classList.toggle('show');
    }

    // Remove the "show" class from all other elements
    var menuItems = document.querySelectorAll('.menu-item-has-children');
    menuItems.forEach(function(item) {
        if (item !== event.target.closest('.menu-item-has-children')) {
            item.classList.remove('show');
        }
    });
}

// Function to remove the "show" class from the parent when focus leaves the entire submenu
function handleFocusOut(event) {
    var submenu = event.target.closest('.sub-menu');
    if (submenu) {
        // Defer the check to ensure the next element is focused first
        setTimeout(function() {
            if (!submenu.contains(document.activeElement)) {
                submenu.closest('.menu-item-has-children').classList.remove('show');
            }
        }, 0);
    }
}

// Add click event listeners to all elements with the class "menu-item-has-children"
var menuItems = document.querySelectorAll('.menu-item-has-children');
menuItems.forEach(function(item) {
    // Add event listener to the <li> element
    item.addEventListener('click', toggleShowClass);
});

// Add focusout event listeners to all <a> tags inside submenus
var submenuLinks = document.querySelectorAll('.sub-menu a');
submenuLinks.forEach(function(link) {
    link.addEventListener('focusout', handleFocusOut);
});
// Remove the "show" class when pressing the escape key
document.addEventListener('keydown', function(event) {
    if (event.key === 'Escape') {
        menuItems.forEach(function(item) {
            item.classList.remove('show');
        });
    }
});



});

// focus trap for mobile menu
document.addEventListener("DOMContentLoaded", function() {
    const applyLink = document.querySelector("#navigation-primary .apply a");
    const mobileNavigationToggle = document.querySelector("#mobile-navigation-toggle");

    if (applyLink && mobileNavigationToggle) {
        // Check if the device width is less than 960px
        if (window.innerWidth < 960) {
            applyLink.addEventListener("keydown", function(event) {
                if (event.key === "Tab") {
                    event.preventDefault();
                    mobileNavigationToggle.focus();
                }
            });
        }
    }
});

// making expandable boxes equal in size
document.addEventListener("DOMContentLoaded", function () {
    const boxes = document.querySelectorAll(".expanding-boxes__box");

    if (boxes.length > 0) {
        boxes.forEach((box) => {
            const title = box.querySelector(".expanding-boxes__title");
            const content = box.querySelector(".expanding-boxes__content");

            // Check if both title and content are available
            if (title && content) {
                // Prepend the title text inside the content paragraph
               // content.textContent = title.textContent + " " + content.textContent;

                // Create a strong element for the title
                const strongTitle = document.createElement("strong");
                strongTitle.textContent = title.textContent;

                // Add the class to the strong element
                strongTitle.classList.add("expanding-boxes__title");

                // Apply additional styles
                strongTitle.style.lineHeight = "1.5";
                strongTitle.style.display = "block";

                // Insert the strong title at the beginning of the content
                content.insertBefore(strongTitle, content.firstChild);

                // Empty the title paragraph
                title.textContent = "";

                // Set initial max height to 200px
                content.style.maxHeight = "200px";

                // Add hover effect to change max height to 500px
                box.addEventListener("mouseover", function () {
                    content.style.maxHeight = "500px";
                });

                box.addEventListener("mouseout", function () {
                    content.style.maxHeight = "200px";
                });
            }
        });
    }
});